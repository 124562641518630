import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import WebsiteDefaultPage from '../components/Page/Base';
import { Hero, Leaders, Testimonies, GreenDivider } from '../components/PageSpecific/homepage';
import {ReadyPayUpgrade} from '../components/PageSpecific/products';
import { LIGHT_DARK } from '../constants';
import { Width } from '../styles';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const GlobalStyleOverride = createGlobalStyle`
  @media print, screen and (min-width: ${Width.BREAK_MD}px){
    html {
      overflow: unset;
    }
  }
`;

const IndexPage = ({ data: { homepageImages } }) => {
  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="vaut71hvmr3ge7etbsveojrcap70yl" />
      </Helmet>
      <GlobalStyleOverride />
      <WebsiteDefaultPage pageState={{ theme: LIGHT_DARK.DARK }}>
        <Hero />
        <ReadyPayUpgrade />
        <Testimonies assets={homepageImages.edges} />
        <Leaders />
      </WebsiteDefaultPage>
      <GreenDivider/>
    </>
  );
};

export const HomepageQuery = graphql`
  query {
    homepageImages: allFile(
      filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "homepage" } }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          id
          base
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;

export default IndexPage;